import React from 'react';
import { Link } from "react-router-dom";

const WidgetComments = () => {
    return (
        <div className="widget widget_recent_comments">
            <h5 className="widget-title"><span className="widget-line"></span>Recent Comments</h5>

            <ul id="recentcomments">
                <li className="recentcomments">
                    <span className="comment-author-link">Tommy Andersen</span> on
                    <Link title="Tommy Andersen" to={"/blog-single-post#comments"}>Lorem ipsum dolor sit amet, consectetur adipiscing elit…</Link>
                </li>

                <li className="recentcomments">
                    <span className="comment-author-link">Rosie Ford</span> on
                    <Link title="Rosie Ford" to={"/blog-single-post#comments"}>Lorem ipsum dolor sit amet, consectetur adipiscing elit…</Link>
                </li>

                <li className="recentcomments">
                    <span className="comment-author-link">Arthur Hopin</span> on
                    <Link title="Arthur Hopin" to={"/blog-single-post#comments"}>Lorem ipsum dolor sit amet, consectetur adipiscing elit…</Link>
                </li>
            </ul>
        </div>
    );
};

export default WidgetComments;
