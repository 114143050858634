import React from 'react';
import { Link } from "react-router-dom";

const BlogTags = () => {
    return (
        <div className="tags">
            <p>
                <Link title="Wedding" to={"/blog"}>Wedding</Link>
                <Link title="Anniversary" to={"/blog"}>Anniversary</Link>
                <Link title="Honeymoon" to={"/blog"}>Honeymoon</Link>
            </p>
        </div>
    );
};

export default BlogTags;