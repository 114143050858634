import React from 'react';

const PageTitlePortfolioInside = ({ portfolio }) => {
    return (
        <section id="page-title" className="block">
            <div className="wrapper">
                <div className="page-title-content">
                    <div className="page-title-img">
                        <img src={portfolio.imgSrc} alt={portfolio.title} />
                    </div>
                    <div className="page-title-description spacer p-top-md-media-sm">
                        <div className="title">
                            <h1 className="large">{portfolio.client}</h1>
                        </div>

                        <div className="description">
                            <p>{portfolio.type}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PageTitlePortfolioInside;