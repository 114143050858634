import { useState } from 'react';

import { MasonryPhotoAlbum } from "react-photo-album";
import "react-photo-album/masonry.css";

import Lightbox from "yet-another-react-lightbox";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

function PortfolioAlbum({ portfolio }) {
    const [index, setIndex] = useState(-1);

    const photos = portfolio.slider.map(x => ({
        ...x,
        alt: portfolio.title,
    }))

    return (
        <section id="page-album" className="block spacer p-top-xl">
            <div className="wrapper">
                <MasonryPhotoAlbum photos={photos}
                    onClick={({ index }) => setIndex(index)}
                    render={{
                        extras: (_, { photo }) => (
                            photo.type === 'video' ? <i className="fab fa-youtube album-video-icon"></i> : undefined
                        ),
                    }}
                    columns={(containerWidth) => {
                        if (containerWidth < 400) return 1;
                        return 3;
                    }}
                />

                <Lightbox
                    slides={photos}
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    plugins={[Slideshow, Thumbnails, Zoom]}
                    thumbnails={{
                        position: 'end',
                        showToggle: true,
                        hidden: true,
                    }}
                    controller={{ closeOnBackdropClick: true, closeOnPullUp: true, closeOnPullDown: true }}
                    render={{
                        slide: ({ slide, rect }) =>
                            slide.type === "video" ? (
                                <iframe
                                    width={Math.min(
                                        slide.width,
                                        rect.width,
                                        (slide.width * rect.height) / slide.height
                                    )}
                                    height={Math.min(
                                        slide.height,
                                        rect.height,
                                        (slide.height * rect.width) / slide.width
                                    )}
                                    src={slide.url}
                                    title={slide.title}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen
                                />
                            ) : undefined
                    }}
                />
            </div>
        </section>
    );
};

export default PortfolioAlbum;