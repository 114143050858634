import React from 'react';
import { Link } from "react-router-dom";

const WidgetTagCloud = () => {
    return (
        <div className="widget widget_tag_cloud">
            <h5 className="widget-title">Tags</h5>

            <div className="tagcloud">
                <Link title="Honeymoon" to={"/blog"} className="tag-cloud-link">Honeymoon</Link>
                <Link title="Anniversary" to={"/blog"} className="tag-cloud-link">Anniversary</Link>
                <Link title="Wedding" to={"/blog"} className="tag-cloud-link">Wedding</Link>
                <Link title="Flowers" to={"/blog"} className="tag-cloud-link">Flowers</Link>
            </div>
        </div>
    );
};

export default WidgetTagCloud;
