import React from 'react';

const PageTitlePortfolio = () => {
    return (
        <section id="page-title">
            <div className="wrapper">
                <div className="title">
                    <h1 className="large">Portfolio.</h1>
                </div>

                <div className="description-2">
                    <p>Explore our diverse portfolio showcasing a blend of stunning landscapes, captivating portraits, and dynamic event photography. Each photograph tells a unique story, meticulously crafted to perfection. </p>
                </div>
            </div>
        </section>
    );
};

export default PageTitlePortfolio;
