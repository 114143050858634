import React from 'react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const PageTitleHome = () => {
    return (
        <section id="page-title" className="block">
            <div className='position-relative'>
                <Swiper
                    spaceBetween={30}
                    effect={'fade'}
                    speed={500}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[EffectFade, Autoplay]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <img src="https://esycv8lqqfdqvcfb.public.blob.vercel-storage.com/banner/4.jpg" alt="Home Banner 1" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="https://esycv8lqqfdqvcfb.public.blob.vercel-storage.com/banner/5.jpg" alt="Home Banner 2" />
                    </SwiperSlide>
                </Swiper>
                <div className='home-bg' />
                <div className="home-banner wrapper">
                    <div className="page-title-content">

                        <div className="page-title-description">
                            <div className="title">
                                <h1 className="large">Plus977<br />Production.</h1>
                            </div>

                            <div className="description">
                                <p>Welcome to Plus977 Production, where every moment is captured with precision and creativity. Discover the art of photography through our lens and experience the beauty of life's fleeting moments.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PageTitleHome;
