import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import React from "react";
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Page404 from "./pages/404";
import About from "./pages/About";
import Blog from "./pages/Blog";
import BlogSinglePost from "./pages/BlogSinglePost";
import Contacts from "./pages/Contacts";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import Portfolio from "./pages/Portfolio";
import PortfolioInside, { loader as portfolioLoader } from "./pages/PortfolioInside";
import SearchResults from './pages/SearchResults';
import ServiceInside, { loader as serviceLoader } from "./pages/ServiceInside";
import UserInterface from './pages/UserInterface';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <Page404 />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: 'about',
        element: <About />
      },
      {
        path: 'service/:serviceId',
        element: <ServiceInside />,
        loader: serviceLoader
      },
      {
        path: 'portfolio',
        element: <Portfolio />
      },
      {
        path: 'portfolio/:portfolioId',
        element: <PortfolioInside />,
        loader: portfolioLoader
      },
      {
        path: 'blog',
        element: <Blog />
      },
      {
        path: 'blog/:blogId',
        element: <BlogSinglePost />
      },
      {
        path: 'contacts',
        element: <Contacts />
      },
      {
        path: 'ui',
        element: <UserInterface />
      },
      {
        path: 'search-results',
        element: <SearchResults />
      },
    ]
  }
]);

function App() {
  return (
    <HelmetProvider>
      <RouterProvider router={router} />
      <Analytics />
      <SpeedInsights />
    </HelmetProvider>
  );
}

export default App;
