import React from 'react';
import { Link } from "react-router-dom";

const WidgetCategories = () => {
    return (
        <div className="widget widget_categories">
            <h5 className="widget-title">Categories</h5>

            <ul>
                <li className="cat-item">
                    <Link title="Wedding" to={"/blog"}>Wedding</Link>
                </li>

                <li className="cat-item">
                    <Link title="Anniversary" to={"/blog"}>Anniversary</Link>
                </li>

                <li className="cat-item">
                    <Link title="Honeymoon" to={"/blog"}>Honeymoon</Link>
                </li>

                <li className="cat-item">
                    <Link title="Flowers" to={"/blog"}>Flowers</Link>
                </li>
            </ul>
        </div>
    );
};

export default WidgetCategories;
