import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLoaderData } from "react-router-dom";

import PageTitlePortfolioInside from '../blocks/page-title/PageTitlePortfolioInside';
import PortfolioAlbum from '../blocks/portfolio/PortfolioAlbum';
import PortfolioPagination from '../blocks/portfolio/PortfolioPagination';

import GalleryItemsData from '../data/gallery/galleryItems';

export function loader({ params }) {
    const selectedIndex = GalleryItemsData.findIndex(x => x.link === params.portfolioId);
    const portfolio = GalleryItemsData[selectedIndex];
    const selectedCategory = portfolio.category;

    let previous = null;
    let next = null;
    const length = GalleryItemsData.length;

    for (let i = 1; i < length; i++) {
        const prevIndex = (selectedIndex - i + length) % length;
        const nextIndex = (selectedIndex + i) % length;

        if (!previous && GalleryItemsData[prevIndex].category === selectedCategory) {
            previous = GalleryItemsData[prevIndex];
        }

        if (!next && GalleryItemsData[nextIndex].category === selectedCategory) {
            next = GalleryItemsData[nextIndex];
        }

        if (previous && next) {
            break;
        }
    }

    return {
        portfolio, pagination: {
            previous,
            next
        }
    }
}

const PortfolioInside = () => {
    document.body.className = "";
    document.body.classList.add('single');
    document.body.classList.add('single-portfolio');
    const { portfolio, pagination } = useLoaderData();
    return (
        <Fragment>
            <Helmet>
                <meta charSet="UTF-8" />
                <title>Portfolio inside | Plus977 Production</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>

            <main id="main" className="site-main">
                <div id="page-content">
                    <div className="content">
                        <div id="single">
                            <div className="single-content">
                                <PageTitlePortfolioInside portfolio={portfolio} />

                                {/* <PortfolioProject portfolio={portfolio} /> */}

                                <PortfolioAlbum portfolio={portfolio} />

                                <PortfolioPagination pagination={pagination} parent={'portfolio'} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </Fragment>
    );
};

export default PortfolioInside;
