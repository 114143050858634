import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from "react-router-dom";

import PageTitleSearch from '../blocks/page-title/PageTitleSearch';
import SearchItemsData from '../data/search-results/searchItems';

const SearchResults = () => {
    document.body.className = "";
    document.body.classList.add('search-results');

    return (
        <Fragment>
            <Helmet>
                <meta charSet="UTF-8" />
                <title>Search results | Plus977 Production</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>

            <main id="main" className="site-main">
                <PageTitleSearch />

                <section id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="row gutter-width-md with-pb-lg">
                            {SearchItemsData && SearchItemsData.map((item, key) => {
                                return (
                                    <div key={key} className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div className="card card-post">
                                            <div className="card-body p-0">
                                                <h4 className="card-title">
                                                    <Link title={item.title} to={item.link}>{item.title}</Link>
                                                </h4>

                                                <div className="card-meta">
                                                    <p>
                                                        <span><Link to={item.categoryLink}>{item.categoryTitle}</Link></span>
                                                    </p>
                                                </div>


                                                <p className="card-text">{item.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>
            </main>

        </Fragment>
    );
};

export default SearchResults;
