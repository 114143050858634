import React from 'react';

const PageTitleAbout = () => {
    return (
        <section id="page-title">
            <div className="wrapper">
                <div className="title">
                    <h1 className="large">About.</h1>
                </div>

                <div className="description-2">
                    <p>At Plus977 Production, we are passionate about transforming moments into timeless memories. Our team of skilled photographers is dedicated to delivering exceptional quality and personalized service to every client. </p>
                </div>
            </div>
        </section>
    );
};

export default PageTitleAbout;
